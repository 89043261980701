import { getCNStreetList, getCommonCoutyList } from "@/api/index";
export default {
  name: "md-address-picker",
  props: {
    counrty_code: {
      type: String
    },
    // 默认各列选中index数组
    index_list: {
      type: Array,
      default() {
        return [];
      }
    },
    total_list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  emits: ["handleClosePicker", "handleConfirmPicker"],
  computed: {
    cancel_title() {
      return this.$t("address_list.delete_alert_cancel");
    },
    confirm_title() {
      return this.$t("address_list.delete_alert_confirm");
    }
  },
  data() {
    return {
      // 省市区横滑
      swiper_index: 0,
      swiper_length: 1,
      // 已选省市区数组
      selectedList: [],
      //当前展示list
      currentShowList: []
    };
  },
  watch: {
    index_list: {
      async handler(newValue) {
        // 序列化反序列化-防止组件和页面共用数组
        this.selectedList = JSON.parse(JSON.stringify(newValue));
        this.swiper_index = newValue.length ? newValue.length - 1 : 0;
        this.swiper_length = newValue.length ? newValue.length : 1;
        this.currentShowList = await this.currentColumnList(this.swiper_index);
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit("handleClosePicker");
    },
    async selectedChangeAction(index) {
      this.swiper_index = index;
      this.$refs.mySwipe.swipeTo(index);
      this.currentShowList = await this.currentColumnList(index);
    },
    swiperChangeAction(index) {
      // 控制是否允许手动切换省市区list
      this.selectedChangeAction(index);
    },
    select_placeholer(index) {
      let placeholder = "";
      switch (index) {
        case 0:
          placeholder = "选择省份/地区";
          break;
        case 1:
          placeholder = "选择城市";
          break;
        case 2:
          placeholder = "选择区/县";
          break;
        case 3:
          placeholder = "选择街道";
          break;
        default:
          break;
      }
      return "请" + placeholder;
    },
    async selectAction(item, column_index) {
      let selectedObj = {
        id: item.id,
        name: item.name,
        auto_selected: item.auto_selected || false
      };
      if (column_index == 2) {
        selectedObj = Object.assign(selectedObj, {
          postal_code: item.postal_code || ""
        });
      }

      // 更新选中列表
      this.selectedList.splice(column_index, this.selectedList.length - column_index, selectedObj);
      try {
        if (item.list && item.list.length == 1) {
          // 自动选择唯一选项
          this.swiper_length = column_index + 2;
          this.swiper_index = column_index + 1;
          this.selectAction(item.list[0], column_index + 1);
        } else if (item.list && item.list.length > 0) {
          // 有下级数据，展示下级
          this.swiper_length = column_index + 2;
          this.swiper_index = column_index + 1;
          this.currentShowList = await this.currentColumnList(this.swiper_index);
        } else {
          // 请求下级数据
          await this.sendNextListRequest(item, column_index, true);
        }
        this.$refs.mySwipe.swipeTo(this.swiper_index);
      } catch (error) {
        // 发生错误时，直接确认当前选择
        console.error('地址选择出错:', error);
        this.confirm();
      }
    },
    sendNextListRequest(column_item, column_index, should_swip = false) {
      return new Promise(resolve => {
        if (column_index == 1) {
          // 请求区/县数据
          getCommonCoutyList({
            id: column_item.id,
            code: this.counrty_code
          }).then(res => {
            // 修改这里：如果没有数据，直接确认当前选择
            if (res.status == 0) {
              if (res.data && res.data.length) {
                column_item.list = res.data;
                resolve(res.data);
                if (should_swip) {
                  this.swiper_length = column_index + 2;
                  this.swiper_index = column_index + 1;
                  this.$refs.mySwipe.swipeTo(this.swiper_index);
                  this.currentShowList = res.data;
                }
              } else {
                // 没有下级数据，直接确认当前选择
                this.confirm();
              }
            } else {
              // 请求失败，也直接确认当前选择
              this.confirm();
            }
          }).catch(() => {
            // 请求异常，直接确认当前选择
            this.confirm();
          });
        } else if (column_index == 2) {
          // 请求街道数据
          getCNStreetList({
            county_id: column_item.id,
            code: this.counrty_code
          }).then(res => {
            if (res.status == 0) {
              if (res.data && res.data.streets && res.data.streets.length) {
                column_item.list = res.data.streets;
                resolve(res.data.streets);
                if (should_swip) {
                  this.swiper_length = column_index + 2;
                  this.swiper_index = column_index + 1;
                  this.$refs.mySwipe.swipeTo(this.swiper_index);
                  this.currentShowList = res.data.streets;
                }
              } else {
                // 没有街道数据，直接确认
                this.confirm();
              }
            } else {
              // 请求失败，直接确认
              this.confirm();
            }
          }).catch(() => {
            // 请求异常，直接确认
            this.confirm();
          });
        } else if (column_index == 3) {
          // 街道选择完成，直接确认
          this.confirm();
        }
      });
    },
    async confirm() {
      this.$emit("handleClosePicker");
      this.$emit("handleConfirmPicker", this.selectedList);
    },
    // 根据外部传入已选中省/市/区/街道-请求对应各级缺失数据
    async currentColumnList(column_index) {
      // 省市区总数据
      const list = this.total_list;
      // 外部传入选中省/市/区/街道数据
      const selectedList = this.selectedList;
      if (list && list.length) {
        let columnList = list || [];
        console.log("columnList---", columnList.length, column_index);
        for (var i = 0; i < column_index; i++) {
          if (columnList) {
            for (let column_item of columnList) {
              if (Number(column_item.id) == Number(selectedList[i].id)) {
                columnList = column_item.list;
                if (column_item.list) {
                  //如果有list直接遍历
                  columnList = column_item.list;
                } else {
                  //没有list，触发请求
                  columnList = await this.sendNextListRequest(column_item, i, i == column_index - 1);
                  break;
                }
              }
            }
          } else {
            columnList = await this.sendNextListRequest(this.selectedList[i], i, i == column_index - 1);
          }
        }
        return columnList;
      }
    }
  }
};