// eslint-disable-next-line no-unused-vars
import { useRoute } from "vue-router";
import { Dialog, Form, showToast, Switch } from "vant";
import { post, get } from "@/utils/apiBase.js";
import { mapState } from "vuex";
import lowerdev from "@/service/lowerdev";
import { getCommonProvinceList } from "@/api/index";
import { env, domClientWidth, domPixelRatio, domTop, domHeight, domClientHeight } from "md-base-tools/env";
import MdAddressPicker from "@@/address/md-address-picker";
import { mdToast } from "../../utils/tools";
export default {
  name: "orderList",
  components: {
    // AddressEdit
    // eslint-disable-next-line vue/no-unused-components
    [Dialog.name]: Dialog.Component,
    // overlay,
    Form,
    Switch,
    MdAddressPicker
  },
  async setup() {
    const route = useRoute();
    const redirectUrl = decodeURIComponent(route.query.redirectUrl);
    let item = route.query.item ? JSON.parse(route.query.item) : null;
    const orderId = route.query.orderId ? route.query.orderId : null;
    const orderType = route.query.orderType ? route.query.orderType : null;
    const add = route.query.add ? route.query.add : false;
    if (orderId && !add) {
      let data = null;
      if (orderType === "project") {
        data = await get("/apis/mdorder/order_address_detail", {
          id: orderId,
          type: "order_id",
          pro_id: ""
        });
        item = data.data.address_info;
      } else {
        data = await get("/mall/order/address/detail", {
          order_id: orderId
        });
        item = data.data.address;
      }
    }
    return {
      id: item ? item.id : "",
      redirectUrl,
      item,
      orderId,
      orderType,
      add
    };
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      //省市区地址
      areaList: [],
      // 显示选择省市区组件
      provinceEnabled: true,
      showProvincePicker: false,
      selectedProvinceList: [],
      //地址ID
      addresid: "",
      env: env,
      // 显示国家区号组件
      quhao: false,
      idx: 0,
      //国际编码列表
      codeList: countryCode.codeList,
      payList: countryCode.payList,
      from: {
        name: "",
        mobile: "",
        code_posi: "中国大陆 +86",
        country_code: "86",
        //省
        pro_posi: "",
        province_id: "",
        //市
        city_posi: "",
        city_id: "",
        //区
        region_posi: "",
        county_id: "",
        //街道
        street: "",
        area: "",
        address_detail: "",
        postal_code: "",
        is_default: false
      }
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    },
    save_btn_enabled() {
      if (!this.from.name || this.from.name.length == 0 || !this.from.mobile || this.from.mobile.length == 0 || !this.from.address_detail || this.from.address_detail.length == 0) {
        return false;
      } else if (this.provinceEnabled) {
        if (!this.from.pro_posi || this.from.pro_posi.length == 0 || !this.from.province_id || this.from.province_id.length == 0 || !this.from.city_posi || this.from.city_posi.length == 0 || !this.from.city_id || this.from.city_id.length == 0) {
          return false;
        }
      }
      return true;
    }
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  mounted() {
    lowerdev.addbind(window, "scroll", this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
    if (this.item) {
      if (this.orderType === "project") {
        this.addresid = this.item.id;
      }
      this.from.pro_posi = this.item.province;
      this.from.province_id = this.item.province_id;
      this.from.city_posi = this.item.city;
      this.from.city_id = this.item.city_id;
      this.from.region_posi = this.item.county;
      this.from.county_id = this.item.area_id;
      this.from.street = this.item.street;
      this.from.name = this.item.address_name;
      this.from.mobile = this.item.address_mobile;
      if (this.from.pro_posi && this.from.province_id) {
        this.from.area = this.from.pro_posi;
        if (this.from.city_posi && this.from.city_id) {
          this.from.area += "\n" + this.from.city_posi;
          if (this.from.region_posi && this.from.county_id) {
            this.from.area += "\n" + this.from.region_posi;
            if (this.from.street) {
              this.from.area += "\n" + this.from.street;
            }
          }
        }
      }
      console.log("selectedProvinceList---", this.selectedProvinceList);
      this.from.address_detail = this.item.address;
      this.from.postal_code = Number(this.item.address_postal_code);
      this.from.is_default = this.item.is_default ? true : false;
      if (this.item.country_code) {
        this.codeList.map((e, r) => {
          // eslint-disable-next-line eqeqeq
          if (e.id == this.item.country_code) {
            this.idx = r;
            this.from.code_posi = `${e.name} +${e.id}`;
            this.from.country_code = `${e.id}`;
          }
        });
      }
    }

    // 请求省市区数据
    this.sendProvinceListRquest();
  },
  watch: {},
  beforeUnmount() {
    lowerdev.removebind(window, "scroll", this.reloadScroll);
  },
  methods: {
    //切换-国家编码
    onConfirmquhao({
      selectedOptions
    }) {
      this.quhao = false;
      this.from.code_posi = selectedOptions[0].text;
      this.from.country_code = selectedOptions[0].value;

      //切换国家编码-清空省市区街道详细地址邮编
      this.from.pro_posi = "";
      this.from.province_id = "";
      this.from.city_posi = "";
      this.from.city_id = "";
      this.from.region_posi = "";
      this.from.county_id = "";
      this.from.street = "";
      this.from.area = "";
      this.from.postal_code = "";

      // 重新请求省市区
      this.sendProvinceListRquest();
    },
    //根据国家编码-请求省市区数据
    async sendProvinceListRquest() {
      this.areaList = [];
      const res = await getCommonProvinceList({
        code: this.from.country_code
      });
      this.areaList = res.data;
      this.provinceEnabled = res.data.length > 0;

      // 只有和当前省市区匹配才下发选中index数组
      let province_index = this.areaList.findIndex(item => item.id == this.from.province_id);
      if (province_index == -1) {
        //如果省ID和根据当前国家编码获取省数据不一致-清空省市区街道详细地址邮编
        this.from.pro_posi = "";
        this.from.province_id = "";
        this.from.city_posi = "";
        this.from.city_id = "";
        this.from.region_posi = "";
        this.from.county_id = "";
        this.from.street = "";
        this.from.area = "";
        this.from.postal_code = "";
      }
    },
    //显示-省市区选择
    handleShowProvincePivker() {
      if (this.areaList.length == 0) {
        mdToast("正在请求省市区数据，请稍候重试");
        return;
      }
      this.selectedProvinceList = [];

      // 只有和当前省市区匹配才下发选中index数组
      let province_index = this.areaList.findIndex(item => item.id == this.from.province_id);
      if (this.from.pro_posi && this.from.province_id && province_index >= 0) {
        this.selectedProvinceList.push({
          id: this.from.province_id,
          name: this.from.pro_posi
        });
        if (this.from.city_posi && this.from.city_id) {
          this.selectedProvinceList.push({
            id: this.from.city_id,
            name: this.from.city_posi
          });
          if (this.from.region_posi && this.from.county_id) {
            this.selectedProvinceList.push({
              id: this.from.county_id,
              name: this.from.region_posi
            });
            if (this.from.street) {
              this.selectedProvinceList.push({
                name: this.from.street
              });
            }
          }
        }
      }
      this.showProvincePicker = true;
    },
    //关闭-省市区选择
    handleCloseProvincePicker() {
      this.showProvincePicker = false;
    },
    //确定-省市区选择
    handleConfirmProvincePicker(e) {
      this.from.area = "";
      if (e.length > 0) {
        // 省
        this.from.pro_posi = e[0].name;
        this.from.province_id = e[0].id;
        this.from.area += this.from.pro_posi;
        if (e.length > 1) {
          // 市
          this.from.city_posi = e[1].name;
          this.from.city_id = e[1].id;
          this.from.area += "\n" + this.from.city_posi;
          if (e.length > 2) {
            // 区
            this.from.region_posi = e[2].name;
            this.from.county_id = e[2].id;
            this.from.postal_code = e[2].postal_code || "";
            this.from.area += "\n" + this.from.region_posi;
            if (e.length > 3) {
              // 街道
              this.from.street = e[3].name;
              this.from.area += "\n" + this.from.street;
            }
          }
        }
      }
    },
    onSubmit() {
      if (this.save_btn_enabled) {
        this.addAddress();
      }
    },
    // 保存-新增/编辑
    async addAddress() {
      const data = {
        name: this.from.name,
        country_code: this.from.country_code,
        mobile: this.from.mobile,
        province: this.from.pro_posi,
        province_id: this.from.province_id,
        city: this.from.city_posi,
        city_id: this.from.city_id,
        county: this.from.region_posi,
        area_id: this.from.county_id,
        street: this.from.street.indexOf("暂不填写") > -1 ? "" : this.from.street,
        postal_code: this.from.postal_code,
        detail: this.from.address_detail,
        is_default: this.from.is_default ? 1 : 0
      };
      let url = "/apis/address/naa";
      if (this.id) {
        data.address_id = this.id;
        url = "/apis/address/nua";
      }
      let updateAddress = null;
      if (this.add || this.id) {
        updateAddress = await post(url, data);
      }
      if (this.orderId) {
        if (this.orderType === "shop") {
          url = "/mall/order/address";
          data.address_mobile = data.mobile;
          data.address_name = data.name;
          data.address = data.detail;
          data.address_postal_code = data.postal_code;
          updateAddress = await post(url, {
            order_id: this.orderId,
            address: JSON.stringify(data)
          });
        } else if (this.orderType === "project") {
          url = "/apis/mdorder/order_modify_address";
          data.address_mobile = data.mobile;
          data.address_name = data.name;
          data.address = data.detail;
          data.address_postal_code = data.postal_code;
          data.order_id = this.orderId;
          data.id = this.addresid;
          data.user_type = 0;
          // data.user_id = this.userId
          updateAddress = await post(url, data);
        }
      } else {
        updateAddress = await post(url, data);
      }
      if (updateAddress) {
        if (Number(updateAddress.status) == 0) {
          showToast({
            type: "text",
            message: updateAddress.message ? updateAddress.message : "操作成功",
            onClose: () => {
              // eslint-disable-next-line eqeqeq
              // if (this.redirectUrl != 'undefined') {
              //   location.href = this.redirectUrl
              // } else {
              this.dropBack();
              // }
            }
          });
        } else {
          mdToast(updateAddress.message ? updateAddress.message : "操作异常");
        }
      }
    },
    dropBack() {
      history.back();
      // if (history.length > 1) {
      //   this.$router.back(-1)
      // } else {
      //   lowerdev.openUrl()
      // }
    },
    reloadScroll() {
      const localTop = domTop();
      if (this.loading) {
        return false;
      }
      if (localTop && domHeight() < localTop + domClientHeight() + 50) {
        this.loading = true;
        this.page_index = this.page_index + 1;
      }
    }
  }
};
const countryCode = {
  codeList: [{
    id: "86",
    name: "中国大陆"
  }, {
    id: "852",
    name: "中国香港"
  }, {
    id: "853",
    name: "中国澳门"
  }, {
    id: "886",
    name: "中国台湾"
  }, {
    id: "61",
    name: "澳大利亚"
  }, {
    id: "1",
    name: "加拿大"
  }, {
    id: "33",
    name: "法国"
  }, {
    id: "49",
    name: "德国"
  }, {
    id: "62",
    name: "印度尼西亚"
  }, {
    id: "39",
    name: "意大利"
  }, {
    id: "81",
    name: "日本"
  }, {
    id: "82",
    name: "韩国"
  }, {
    id: "60",
    name: "马来西亚"
  }, {
    id: "64",
    name: "新西兰"
  }, {
    id: "31",
    name: "荷兰"
  }, {
    id: "63",
    name: "菲律宾"
  }, {
    id: "7",
    name: "俄罗斯"
  }, {
    id: "65",
    name: "新加坡"
  }, {
    id: "46",
    name: "瑞典"
  }, {
    id: "66",
    name: "泰国"
  }, {
    id: "44",
    name: "英国"
  }, {
    id: "380",
    name: "乌克兰"
  }, {
    id: "1",
    name: "美国"
  }, {
    id: "84",
    name: "越南"
  }],
  payList: [{
    text: '+86  中国大陆',
    value: 86
  }, {
    text: "+852 中国香港",
    value: 852
  }, {
    text: "+853 中国澳门",
    value: 853
  }, {
    text: "+886 中国台湾",
    value: 886
  }, {
    text: "+61  澳大利亚",
    value: 61
  }, {
    text: "+1   加拿大",
    value: 1
  }, {
    text: "+33  法国",
    value: 33
  }, {
    text: "+49  德国",
    value: 49
  }, {
    text: "+62  印度尼西亚",
    value: 62
  }, {
    text: "+39  意大利",
    value: 39
  }, {
    text: "+81  日本",
    value: 81
  }, {
    text: "+82  韩国",
    value: 82
  }, {
    text: "+60  马来西亚",
    value: 60
  }, {
    text: "+64  新西兰",
    value: 64
  }, {
    text: "+31  荷兰",
    value: 31
  }, {
    text: "+63  菲利宾",
    value: 63
  }, {
    text: "+7   俄罗斯",
    value: 7
  }, {
    text: "+65  新加坡",
    value: 65
  }, {
    text: "+46  瑞典",
    value: 46
  }, {
    text: "+66  泰国",
    value: 66
  }, {
    text: "+44  英国",
    value: 44
  }, {
    text: "+380 乌克兰",
    value: 380
  }, {
    text: "+1   美国",
    value: 1
  }, {
    text: "+84  越南",
    value: 84
  }],
  payCode: [86, 852, 853, 886, 61, 1, 33, 49, 62, 39, 81, 82, 60, 64, 31, 63, 7, 65, 46, 66, 44, 380, 1, 84]
};